import {equalString} from '@util/strings'

export const parseQueryParams = (url: string) => {
    let searchParam = new Map<string, string>()
    if (!url?.includes('?')) return searchParam

    const params = url.split('?')[1].split('&')
    params.map(item => {
        const key = item.split('=')[0]
        const value = item.split('=')[1]

        searchParam.set(key, value)
    })

    return searchParam
}

interface FilteredParam {
    key: string
    value?: string
}

export const createUrl = (url: string, params?: {}) => {
    let queryText = ''

    const filteredParamList: FilteredParam[] = []
    const keys = Object.keys(params)
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i]

        if (params[key]) {
            filteredParamList?.push({key: key, value: params[key]})
        }
    }

    filteredParamList?.map((item, index) => {
        if (index === 0) {
            queryText += `?${item.key}=${item.value}`
        } else {
            queryText += `&${item.key}=${item.value}`
        }
    })

    return `${url}${queryText}`
}

const extractHostname = (url: string) => {
    let hostname

    //find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2]
    } else {
        hostname = url.split('/')[0]
    }

    //find & remove port number
    hostname = hostname.split(':')[0]

    //find & remove "?"
    hostname = hostname.split('?')[0]

    return hostname
}

export interface IUrl {
    protocol: string
    path: string
    hostname: string
    searchParam: Map<string, string>
}

export const parseUrl = (url: string) => {
    try {
        let protocol = url.split('//')[0]
        let hostname = extractHostname(url)
        let path = url.replace(protocol + '//' + hostname, '').split('?')[0]
        let searchParam = new Map<string, string>()

        const params = url.replace(`${protocol}//${hostname}${path}`, '').replace('?', '').split('&')
        params.map(item => {
            const key = item.split('=')[0]
            const value = item.split('=')[1]

            searchParam.set(key, value)
        })

        let result: IUrl = {
            protocol: protocol,
            path: path,
            hostname: hostname,
            searchParam: searchParam,
        }

        return result
    } catch (e: any) {
        return undefined
    }
}
