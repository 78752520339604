export function isEmptyArray(array?: Array<any>) {
    return (array ?? []).length === 0
}

export function isNotEmptyArray(array: Array<any>) {
    return !isEmptyArray(array)
}

export function getNumberRange(from: number, to: number): number[] {
    return new Array(to - from + 1).fill('').map((v, i) => i + from)
}

export const shuffleArray = (array: any[]) => {
    const shuffledArray = [...array]
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
    }
    return shuffledArray
}
