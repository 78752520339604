export function formatLeftTime(
    leftTimeSecond?: number,
    options?: {
        isShowHour?: boolean
    },
) {
    const {isShowHour = true} = options ?? {}

    let timeSec = Math.floor(leftTimeSecond % 60)
    let timeMin = Math.floor((leftTimeSecond / 60) % 60)
    let timeHour = Math.floor(leftTimeSecond / 3600)

    let hours = '00'
    if (timeHour < 10) {
        hours = `0${timeHour}`
    } else {
        hours = `${timeHour}`
    }

    let minutes = '00'
    if (timeMin < 10) {
        minutes = `0${timeMin}`
    } else {
        minutes = `${timeMin}`
    }

    let seconds = '00'
    if (timeSec < 10) {
        seconds = `0${timeSec}`
    } else {
        seconds = `${timeSec}`
    }

    return `${isShowHour ? `${hours}:` : ''}${minutes}:${seconds}`
}

export function passUnixTime(unixTime) {
    return new Date().getTime() - new Date(unixTime * 1000).getTime()
}
