import React from 'react'

export const IMAGE_ACCEPT = '.jpg, .jpeg, .png, .svg, .webp, .gif, .tiff, .heif, .webp, .heic'
export const MAX_IMAGE_SIZE = 2.25

export const ImageErrorCode = {
    IMAGE_NOT_FOUND: 'IMAGE_NOT_FOUND',
    FAILED_TO_GET_FILE: 'FAILED_TO_GET_FILE',
    FILE_SIZE_EXCEEDS_LIMIT: 'FILE_SIZE_EXCEEDS_LIMIT',
    UNSUPPORTED_FILE_TYPE: 'UNSUPPORTED_FILE_TYPE',
} as const
type TImageErrorCode = (typeof ImageErrorCode)[keyof typeof ImageErrorCode]

export class ImagePasteError extends Error {
    constructor(public code: TImageErrorCode, public details?: Record<string, any>) {
        super()
        this.name = 'ImagePasteError'
    }
}

interface ImagePasteOptions {
    maxSizeMB?: number
    callback?: (file: File) => void
    onError?: (error: Error, type?: 'null' | 'size') => void
}

export const handleImagePaste = async (e?: React.ClipboardEvent<HTMLTextAreaElement>, options?: ImagePasteOptions) => {
    try {
        const {maxSizeMB = MAX_IMAGE_SIZE, callback} = options

        const items = e.clipboardData?.items
        if (!items) {
            throw new ImagePasteError(ImageErrorCode.IMAGE_NOT_FOUND)
        }

        // Convert items to array and find the first image
        const imageItem = Array.from(items).find(item => item.type.startsWith('image/'))
        if (!imageItem) {
            throw new ImagePasteError(ImageErrorCode.IMAGE_NOT_FOUND)
        }

        const file = imageItem.getAsFile()
        if (!file) {
            throw new ImagePasteError(ImageErrorCode.FAILED_TO_GET_FILE)
        }

        // Validate file size
        const fileSizeMB = file.size / (1024 * 1024)
        if (fileSizeMB > maxSizeMB) {
            throw new ImagePasteError(ImageErrorCode.FILE_SIZE_EXCEEDS_LIMIT)
        }

        await callback?.(file)
    } catch (error) {
        if (options.onError && error instanceof ImagePasteError) {
            options.onError(error)
        } else {
            console.error('Error handling image paste:', error)
        }
    }
}
