export const getRemovedMarkdown = (md: string) => {
    const onRemoveMarkdown = require('remove-markdown')
    const removedContent = onRemoveMarkdown(md, {
        stripListLeaders: false,
        listUnicodeChar: '',
        gfm: false,
        useImgAltText: false,
    })
    return removedContent
}
