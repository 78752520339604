export const getDayDifference = (date1: Date, date2: Date) => {
    const Difference_In_Time = date2.getTime() - date1.getTime()
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

    return Math.floor(Difference_In_Days)
}

export const getDateFromYYYYMMDD = (dateNumber: number) => {
    const year = dateNumber / 10000
    const month = (dateNumber % 10000) / 100 - 1
    const day = dateNumber % 100

    return new Date(year, month, day)
}

export const getDateFromMonth = (text: string, isDot: boolean = true) => {
    const year = text?.slice(0, 4)
    const month = text?.slice(4)

    return `${year}${isDot ? '-' : ''}${
        month?.length > 2 ? `${month?.slice(0, 2)}${isDot ? '-' : ''}${month?.slice(2)}` : month
    }`
}

export const getDayDifferenceFromToday = (dateText: string) => {
    return getDayDifference(new Date(Date.parse(dateText)), new Date())
}

export const getHourDifferenceFromToday = (dataText: string) => {
    return Math.floor((Date.now() - Date.parse(dataText)) / (1000 * 60 * 60))
}

export const getDayDifferenceFromDate = (dateText: string) => {
    return getDayDifference(new Date(), new Date(Date.parse(dateText)))
}

export const getHourDifferenceFromDate = (dataText: string) => {
    return Math.floor((Date.parse(dataText) - Date.now()) / (1000 * 60 * 60))
}
